import {
  Box,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  SimpleGrid,
  Square,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import FontAwesomeIcon from "../font-awesome-icon";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { auth } from "../../firebase";

export default function BottomBar() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <SimpleGrid
        backgroundColor={"white"}
        columns={4}
        width={"full"}
        position={"fixed"}
        bottom={0}
        left={0}
        height={16}
        borderTopWidth={"1px"}
        borderTopColor={"gray.200"}
      >
        <Flex
          onClick={() => navigate("/")}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          color={"gray.500"}
          cursor={"pointer"}
        >
          <Text fontSize={"xl"}>
            <FontAwesomeIcon name={"house"} />
          </Text>
          <Text fontSize={"xs"}>首頁</Text>
        </Flex>
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          color={"gray.500"}
          cursor={"pointer"}
        >
          <Text fontSize={"xl"}>
            <FontAwesomeIcon name={"check"} />
          </Text>
          <Text fontSize={"xs"}>待辦事項</Text>
        </Flex>
        <Flex
          onClick={() => navigate("/map")}
          cursor={"pointer"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          color={"gray.500"}
        >
          <Text fontSize={"xl"}>
            <FontAwesomeIcon name={"map-pin"} />
          </Text>
          <Text fontSize={"xs"}>地標</Text>
        </Flex>
        {/* <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        color={"gray.500"}
      >
        <Text fontSize={"xl"}>
          <FontAwesomeIcon name={"key"} />
        </Text>
        <Text fontSize={"xs"}>密碼</Text>
      </Flex> */}
        <Flex
          onClick={onOpen}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          color={"gray.800"}
          cursor={"pointer"}
        >
          <Text fontSize={"xl"}>
            <FontAwesomeIcon name={"list"} />
          </Text>
          <Text fontSize={"xs"}>更多</Text>
        </Flex>
      </SimpleGrid>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Flex alignItems={'center'} columnGap={2}>
              <Square size={12}>
              <Circle size={10} backgroundImage={_.get(auth.currentUser, 'photoURL', '/images/user.png')} backgroundSize={'cover'} backgroundPosition={'center'}></Circle>
              </Square>
              <Box>
                <Text fontSize={'sm'}>{_.get(auth.currentUser, 'displayName', 'Unnamed User')}</Text>
                <Text fontSize={'xs'} fontWeight={'regular'}>{_.get(auth.currentUser, 'email', 'No email')}</Text>
              </Box>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Box paddingTop={12}>
              <Flex alignItems={"center"} columnGap={2}>
                <Square size={12} fontSize={"2xl"}>
                  <FontAwesomeIcon name={"key"} />
                </Square>
                <Text fontWeight={'bold'}>密碼管理</Text>
              </Flex>
              <Flex alignItems={"center"} columnGap={2}>
                <Square size={12} fontSize={"2xl"}>
                  <FontAwesomeIcon name={"rectangle-vertical-history"} />
                </Square>
                <Text fontWeight={'bold'}>相簿</Text>
              </Flex>
            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Circle backgroundColor={"gray.200"} size={12} onClick={onClose}>
              <FontAwesomeIcon name={"times"}></FontAwesomeIcon>
            </Circle>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
