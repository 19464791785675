import {
  Button,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import FontAwesomeIcon from "../font-awesome-icon";
import { AdvancedMarker } from "@vis.gl/react-google-maps";

export default function AttractionPin({ position, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <AdvancedMarker onClick={onOpen} position={position}>
        <Circle
          backgroundColor={"green.500"}
          fontSize={"sm"}
          size={8}
          color={"white"}
          borderWidth={2}
          borderColor={"white"}
          boxShadow={"md"}
          {...props}
        >
          <FontAwesomeIcon name={"mountains"} />
        </Circle>
      </AdvancedMarker>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader textAlign={"center"}>Location Name</DrawerHeader>

          <DrawerBody></DrawerBody>

          <DrawerFooter>
            <Button size={"sm"} variant={"ghost"} colorScheme="blue">
              關閉
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
