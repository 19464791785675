import { Box } from "@chakra-ui/react";
import BottomBar from "../components/bottombar";

export default function AppLayout({ children }) {
  return (
    <>
      <Box paddingBottom={16}>{children}</Box>
      <BottomBar></BottomBar>
    </>
  );
}
