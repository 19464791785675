import {
  APIProvider,
  AdvancedMarker,
  Map,
  useMap,
} from "@vis.gl/react-google-maps";
import AppLayout from "../../layouts/app.layout";
import { useCallback, useEffect, useRef, useState } from "react";
import { Circle, Flex } from "@chakra-ui/react";
import AttractionPin from "../../components/map-pin/attraction-pin";
import _ from "lodash";

export default function MapPage() {
  const mapRef = useRef(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
      });
      const watchId = navigator.geolocation.watchPosition((position) => {
        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    }
  }, []);

  const onMapClicked = useCallback(async (event) => {
    const placeId = _.get(event, "detail.placeId", null);
    // click on the place
    if (placeId) {
      console.log(placeId);
    }
  }, []);

  const MapTools = ({ currentPosition }) => {
    const map = useMap();

    const moveToCurrentPosition = useCallback(
      async (event) => {
        if (currentPosition) {
          map.panTo(currentPosition);
        }
      },
      [map, currentPosition],
    );
    return (
      <Flex
        zIndex={1000}
        padding={2}
        position={"fixed"}
        bottom={16}
        left={0}
        columnGap={2}
      >
        <Flex
          onClick={moveToCurrentPosition}
          padding={2}
          fontSize={"sm"}
          borderRadius={"md"}
          boxShadow={"md"}
          fontWeight={"bold"}
          backgroundColor={"white"}
          cursor={"pointer"}
        >
          返回目前位置
        </Flex>
        {/* <Flex
          onClick={moveToCurrentPosition}
          padding={2}
          fontSize={"sm"}
          borderRadius={"md"}
          boxShadow={"md"}
          fontWeight={"bold"}
          backgroundColor={"white"}
          cursor={'pointer'}
        >
          跟隨當前位置
        </Flex> */}
      </Flex>
    );
  };

  return (
    <AppLayout>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API}>
        <MapTools currentPosition={currentPosition}></MapTools>
        <Flex>
          <Map
            onClick={onMapClicked}
            mapId={"6398707315995076"}
            style={{ width: "100%", height: "calc(100vh - 4rem)" }}
            defaultCenter={{ lat: 24.16335363131227, lng: 120.67708971607712 }}
            defaultZoom={16}
            gestureHandling={"greedy"}
            disableDefaultUI={true}
            onLoad={(map) => (mapRef.current = map)}
          >
            {currentPosition && (
              <AdvancedMarker position={currentPosition}>
                <Circle
                  backgroundColor={"red.600"}
                  fontSize={"sm"}
                  size={4}
                  color={"white"}
                  borderWidth={2}
                  borderColor={"white"}
                  boxShadow={"md"}
                  transition={"all 1s !important"}
                ></Circle>
              </AdvancedMarker>
            )}
            <AttractionPin
              position={{ lat: 24.16345363131227, lng: 120.67718971607712 }}
            />
          </Map>
        </Flex>
      </APIProvider>
    </AppLayout>
  );
}
