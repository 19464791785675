import { Text } from "@chakra-ui/react";

export default function FontAwesomeIcon({
  sharp = false,
  group = "regular",
  name,
}) {
  return (
    <Text
      as={"i"}
      className={`${sharp && "fa-sharp"} fa-${group} fa-${name}`}
    />
  );
}
