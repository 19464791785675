import { Button, Flex, Image, Text } from "@chakra-ui/react";
import FontAwesomeIcon from "../../components/font-awesome-icon";
import { useCallback } from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const navigate = useNavigate();
  const loginWithGoogle = useCallback(async () => {
    await signInWithPopup(auth, new GoogleAuthProvider());
    navigate("/");
  }, [navigate]);

  return (
    <Flex
      justifyContent={"center"}
      position={"fixed"}
      left={0}
      top={0}
      backgroundColor={"gray.50"}
      width={"full"}
      minHeight={"100%"}
    >
      <Flex
        position={"relative"}
        flexDirection={"column"}
        rowGap={12}
        width={"80%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image src="/images/login/head.svg" />
      </Flex>
      <Button
        width={"80%"}
        size={"lg"}
        position={"absolute"}
        bottom={12}
        colorScheme="blue"
        borderRadius={"99em"}
      >
        <Text as={"span"}>
          <FontAwesomeIcon name="google" group="brands" />
        </Text>
        <Text marginLeft={4} as={"span"} onClick={loginWithGoogle}>
          透過 Google 登入
        </Text>
      </Button>
    </Flex>
  );
}
