import { Outlet, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { useEffect } from "react";

export default function MustLoginLayout({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/auth/login");
    }
  }, [navigate]);

  return <>{auth.currentUser && <Outlet />}</>;
}
