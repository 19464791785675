import { Route, Routes } from "react-router-dom";
import IndexPage from "../pages/index.page";
import MustLoginLayout from "../layouts/must-login.layout";
import LoginPage from "../pages/auth/login.page";
import MapPage from "../pages/map/index.page";

export default function GeneralRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MustLoginLayout />}>
        <Route index element={<IndexPage />} />
        <Route path="map" element={<MapPage />} />
      </Route>
      <Route path="auth">
        <Route path="login" element={<LoginPage />} />
      </Route>
    </Routes>
  );
}
