import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import GeneralRoutes from "./routes/general.routes";
import SplashScreenLayout from "./layouts/splash-screen.layout";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider>
    <SplashScreenLayout>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<GeneralRoutes />}></Route>
        </Routes>
      </BrowserRouter>
    </SplashScreenLayout>
  </ChakraProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
